import {createApp} from "vue";
import {createPinia} from "pinia";
import * as Sentry from "@sentry/vue";
import Hotjar from "@hotjar/browser";
import Vue3TouchEvents from "vue3-touch-events";
import {plugin as VueTippy} from "vue-tippy";
import packageJson from '../package.json';

import App from "./App.vue";
import router from "./routes";
import "./assets/scss/index.scss";

import Layout from "./layout/Layout.vue";
import LayoutEmpty from "./layout/LayoutEmpty.vue";
import LayoutNoHeader from "./layout/LayoutNoHeader.vue";

import mixpanel from "mixpanel-browser";
import createGtag from 'vue-gtag-next'
import {UrlUtils} from "./core/utils/index.js";

const pinia = createPinia();
const app = createApp(App)
    .use(Vue3TouchEvents)
    .component("default-layout", Layout)
    .component("empty-layout", LayoutEmpty)
    .component("layout-no-header", LayoutNoHeader);
app.use(
    VueTippy,
    // optional
    {
        directive: "tippy", // => v-tippy
        component: "tippy", // => <tippy/>
        componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
        defaultProps: {
            placement: "auto-end",
            allowHTML: true,
        }, // => Global default options * see all props
    }
);


if (import.meta.env.VITE_ENV === "production") {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({
                router,
            }),
            Sentry.replayIntegration(),
            Sentry.httpClientIntegration(),
        ],
        tracesSampleRate: 0.2,
        profilesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: packageJson.version,
    });
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        debug: true,
        track_pageview: false,
        persistence: "localStorage",
    });
    app.use(createGtag,{
        property:{
            id:import.meta.env.VITE_GOOGLE_TOKEN
        }
    })
    router.afterEach((to)=>{
        window.gtag('config',import.meta.env.VITE_GOOGLE_TOKEN,{
            page_path:to.fullPath,
        })
    })

    const siteId = 5097659;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
}

app.config.globalProperties.$mixpanel = mixpanel;
app.use(router);
app.use(pinia).mount("#app");
