import {DateTime, Settings} from 'luxon';
import {isNull} from "lodash-es";

class DateTimeAdapter {
    /**
     * @type {DateTime}
     */
    _date;

    /**
     *
     * @param {string | Date | DateTime} date
     * @param {string} format
     */
    constructor(date = null, format = null) {
        Settings.defaultLocale = 'pt-BR'
        Settings.defaultZoneName = 'America/Sao_Paulo'

        if (date instanceof DateTime) {
            this._date = date
        }

        if (date instanceof Date) {
            const localDate = date.toISOString()
            this._date = DateTime.fromISO(localDate)
        }

        if (isNull(date) && isNull(format)) {
            this._date = null;
        }

        if (date && format) {
            this._date = DateTime.fromFormat(date, format);
        }

        if (date && !format) {
            this._date = DateTime.fromISO(date);
        }

        if (date === 'now' && !format) {
            this._date = DateTime.local();
        }
    }

    /**
     *
     * @param locale
     * @returns {DateTimeAdapter}
     */
    changeLocale(locale) {
        Settings.defaultLocale = locale;
        return this;
    }

    /**
     *
     * @param zone
     * @returns {DateTimeAdapter}
     */
    changeZone(zone) {
        Settings.defaultZoneName = zone;
        return this;
    }

    /**
     *
     * @param {string | Date | DateTime} date
     * @param {string | null} format
     * @returns {DateTimeAdapter}
     */
    static create(date = 'now', format = null) {
        return new DateTimeAdapter(date, format);
    }

    /**
     *
     * @returns {DateTimeAdapter}
     */
    static instance() {
        return new DateTimeAdapter('now');
    }

    /**
     *
     * @param {string | number}days
     * @returns {DateTimeAdapter}
     */
    addDays(days) {
        return DateTimeAdapter.create(this._date.plus({days}))
    }

    /**
     *
     * @param hours
     * @returns {DateTimeAdapter}
     */
    addHours(hours) {
        return DateTimeAdapter.create(this._date.plus({hours}))
    }

    /**
     *
     * @param minutes
     * @returns {DateTimeAdapter}
     */
    addMinutes(minutes) {
        return DateTimeAdapter.create(this._date.plus({minutes}))
    }

    /**
     *
     * @param seconds
     * @returns {DateTimeAdapter}
     */
    addSeconds(seconds) {
        return DateTimeAdapter.create(this._date.plus({seconds}))
    }

    /**
     *
     * @param weeks
     * @returns {DateTimeAdapter}
     */
    addWeeks(weeks) {
        return DateTimeAdapter.create(this._date.plus({weeks}))
    }

    /**
     *
     * @param months
     * @returns {DateTimeAdapter}
     */
    addMonths(months) {
        return DateTimeAdapter.create(this._date.plus({months}))
    }

    /**
     *
     * @param years
     * @returns {DateTimeAdapter}
     */
    addYears(years) {
        return DateTimeAdapter.create(this._date.plus({years}))
    }

    /**
     *
     * @param days
     * @returns {DateTimeAdapter}
     */
    subDays(days) {
        return DateTimeAdapter.create(this._date.minus({days}))
    }

    /**
     *
     * @param hours
     * @returns {DateTimeAdapter}
     */
    subHours(hours) {
        return DateTimeAdapter.create(this._date.minus({hours}))
    }

    /**
     *
     * @param minutes
     * @returns {DateTimeAdapter}
     */
    subMinutes(minutes) {
        return DateTimeAdapter.create(this._date.minus({minutes}))
    }

    /**
     *
     * @param seconds
     * @returns {DateTimeAdapter}
     */
    subSeconds(seconds) {
        return DateTimeAdapter.create(this._date.minus({seconds}))
    }

    /**
     *
     * @param weeks
     * @returns {DateTimeAdapter}
     */
    subWeeks(weeks) {
        return DateTimeAdapter.create(this._date.minus({weeks}))
    }

    /**
     *
     * @param months
     * @returns {DateTimeAdapter}
     */
    subMonths(months) {
        return DateTimeAdapter.create(this._date.minus({months}))
    }

    /**
     *
     * @param years
     * @returns {DateTimeAdapter}
     */
    subYears(years) {
        return DateTimeAdapter.create(this._date.minus({years}))
    }

    isSameOrBefore(date) {
        return this.isSame(date) || this.isBefore(date);
    }

    isSameOrAfter(date) {
        return this.isSame(date) || this.isAfter(date);
    }

    /**
     *
     * @returns {string}
     */
    toFormatAtom() {
        if (!this.isValid()) {
            return '';
        }
        return this.toDate().toISOString();
    }

    /**
     *
     * @returns {Date}
     */
    toDate() {
        return this._date.toJSDate();
    }

    /**
     *
     * @param {string | null} format
     * @param {string | null} placeholder
     * @returns {string}
     */
    format(format = 'dd/MM/yyyy', placeholder = '') {
        if (!this.isValid()) {
            return placeholder || '';
        }
        return this._date.toFormat(format);
    }

    /**
     *
     * @returns {string}
     */
    toString() {
        if (!this.isValid()) {
            return '';
        }
        return this.toFormatAtom();
    }

    /**
     *
     * @param {string | null} placeholder
     * @returns {string}
     */
    fromNow(placeholder = '') {
        if (this.isValid()) {
            return this._date.toRelative();
        }
        return placeholder;
    }

    /**
     *
     * @param {DateTimeAdapter} date
     * @returns {boolean}
     */
    isAfter(date) {
        if (!this.isValid()) {
            return false;
        }
        return this.startOf('day').toDate() > date.startOf('day').toDate();
    }

    /**
     *
     * @param {DateTimeAdapter} date
     * @returns {boolean}
     */
    isBefore(date) {
        if (!this.isValid()) {
            return false;
        }
        return this.startOf('day').toDate() < date.startOf('day').toDate();
    }

    startOf(unit) {
        return DateTimeAdapter.create(this._date.startOf(unit))
    }

    /**
     *
     * @param {DateTimeAdapter} date
     * @returns {boolean}
     */
    isSame(date) {
        if (!this.isValid()) {
            return false;
        }
        return this._date.hasSame(DateTime.fromJSDate(date.toDate()), 'day');
    }

    /**
     *
     * @returns {boolean}
     */
    beforeNow() {
        return this.isBefore(DateTimeAdapter.create());
    }

    /**
     *
     * @returns {boolean}
     */
    afterNow() {
        return this.isAfter(DateTimeAdapter.create());
    }

    /**
     *
     * @param {DateTimeAdapter} date
     * @returns {boolean}
     */
    lessThan(date) {
        if (!this.isValid()) {
            return false;
        }
        return this.isBefore(date);
    }

    /**
     *
     * @param {DateTimeAdapter} date
     * @returns {boolean}
     */
    moreThan(date) {
        if (!this.isValid()) {
            return false;
        }
        return this.isAfter(date);
    }

    getTime() {
        return this.toDate().getTime()
    }

    /**
     *
     * @returns {boolean}
     */
    isValid() {
        try {
            return this._date.isValid;
        } catch (e) {
            return false;
        }
    }
}

export default DateTimeAdapter;