import {AuthService} from "~/core/services/AuthService.js";
import {useAuthenticationStore} from "~/stores/authenticationStore.js";
import * as Sentry from "@sentry/vue"
import Hotjar from "@hotjar/browser";
import Intercom from "@intercom/messenger-js-sdk";

import mixpanel from "mixpanel-browser";

export const authMiddleware = async (to, from, next) => {
    if (to.meta.hasOwnProperty('public') && to.meta.public) {
        next()
    } else {
        const authService = AuthService.getInstance()
        authService.intendedPath = to
        try {
            await authService.authenticate()
        } catch (url) {
            location.replace(url)
            return false
        }
        const userInfo = useAuthenticationStore()


        if (!userInfo.dataLoaded) {
            await userInfo.loadInfo()
            if (import.meta.env.VITE_ENV === 'production' && userInfo.dataLoaded) {
                const user = {
                    id: userInfo.userInfo.id,
                    nome: userInfo.userInfo.nome,
                    email: userInfo.userInfo.email,
                    hmac: userInfo.userInfo.hmac,
                    organizacao: {
                        id: userInfo.userInfo.org_id,
                        nome: userInfo.userInfo.org_nome
                    }
                }
                Sentry.setUser(user)

                Hotjar.identify(user.id, user);

                mixpanel.identify(user.id)
                mixpanel.people.set({
                    '$name': user.nome,
                    '$email': user.email,
                    '$organizacao_id': user.organizacao.id,
                    '$organizacao_nome': user.organizacao.nome,
                });

                Intercom({
                    app_id: import.meta.env.VITE_INTERCOM_WORKSPACE_ID,
                    email: user.email,
                    user_id: user.id,
                    name: user.nome,
                    hide_default_launcher: true,
                    user_hash: user.hmac
                });
            }
        }
        next()
    }
}